import React, {useState} from "react"
import {SvgRefresh} from "./Svg"

export const ModalForm: React.FunctionComponent<{
    label: string,
    onSave: () => Promise<void>,
    onCancel: () => void,
    children: React.ReactNode
}> = ({label, children, onSave, onCancel}) => {

    let [loading, setLoading] = useState<boolean>(false)

    let save = async () => {
        setLoading(true)
        try {
            await onSave()
        } catch (error) {
            console.error(error)
            alert('Une erreur est survenue, merci de réessayer.')
        }
        setLoading(false)
    }

    return (
        <div className="w-full h-screen bg-opacity-75 bg-black fixed top-0">
        <div className="mt-20 mx-20 rounded bg-white p-5 overflow-scroll h-5/6">
            <div className="w-full text-left text-2xl font-bold"> {label} </div>
            <div className="mt-5">
                {children}
            </div>
            <div className="text-right">
            <button className="bg-gray-400 w-32 text-white font-bold p-2 rounded text-sm" onClick={onCancel} > Annuler </button>
            <button className="bg-blue-500 w-32 text-white font-bold p-2 rounded text-sm ml-2" onClick={save}>
            { loading ? <div className="w-5 h-5 mx-auto animate-spin"><SvgRefresh /></div> : <span> Sauvegarder </span> }
            </button>
            </div>
        </div>
        </div>
    )
}