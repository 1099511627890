import React, {useState} from "react"
import {SvgRefresh} from "./Svg"

export const LoadingButton: React.FunctionComponent<{title: string, onClick: () => Promise<void>}> = ({title, onClick}) => {

    let [loading, setLoading] = useState<boolean>(false)

    let click = async () => {
        setLoading(true)
        await onClick()
        setLoading(false)
    }

    return (
        <div className="flex items-center">
            <button onClick={click} className="bg-blue-500 hover:bg-blue-dark text-white py-2 px-4 rounded" type="button">
            { loading ? <div className="w-5 h-5 mx-auto animate-spin"><SvgRefresh /></div> : <span> {title} </span> }
            </button>
        </div>
    )
}