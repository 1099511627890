const axios = require('axios')

class CMS {

    constructor(){
        this.__base_url = process.env.REACT_APP_CMS_URL
        this.__auth_url = `${this.__base_url}/auth/local`
        this.__upload_url = `${this.__base_url}/upload`
        this.__brands_url = `${this.__base_url}/marques`
        this.__categories_url = `${this.__base_url}/categories`
        this.__product_base_url = `${this.__base_url}/produits/`
        this.__category_url = (id) => `${this.__base_url}/categories/${id}`
        this.__product_url = (id) => `${this.__base_url}/produits/${id}`
    }

    /**
     * return marques[]
     * marque : {id, name, logoUrl}
     */
    async get_brands(){
        const response = await axios.get(this.__brands_url, {...this.authenticated_header()})
        return response.data.map(brand => ({
            id: brand.id,
            name: brand.Nom,
            logo: brand.Logo && brand.Logo.id && {
                name: brand.Logo.name,
                url: brand.Logo.formats.thumbnail.url,
                id: brand.Logo.id
            }
        }))
    }

    /**
     * upload files  
     */
    async upload(file) {
        const formData = new FormData();
        console.log(file)
        formData.append('files', file)
        return axios.post(this.__upload_url, formData, {
            headers: { 
                'Content-Type': 'multipart/form-data',
                ...this.jwt_authorization()
            },
        })
    }

    /**
     * insert a new brand
     * form {name, logoFileObject}
     */
    async insert_brand(form) {

        console.log(form)

        if (form.logo instanceof File){
            let response = await this.upload(form.logo)
            form.logo = response.data[0].id
            console.log(form.logo)
        }

        if (form.id) {
            return axios.put(this.__brands_url+'/'+form.id, {
                Nom: form.name,
                Logo: form.logo
            }, {...this.authenticated_header()})
        } else {
            return axios.post(this.__brands_url, {
                Nom: form.name,
                Logo: form.logo
            }, {...this.authenticated_header()})
        }
        
    } 

    async delete_brand(id) {
        return axios.delete(this.__brands_url+'/'+id, {...this.authenticated_header()})
    }

    /**
     * return category[] : 
     * category : { id, title, submenus: category[] }[]
     */
    async get_categories(){
        const response = await axios.get(this.__categories_url, {...this.authenticated_header()})
        return this.categorie_list_to_tree(response.data)
    }

    /**
     * return category : 
     * { id, title, submenus: category[] }
     */
    async get_category(id){
        const response = await axios.get(this.__category_url(id), {...this.authenticated_header()})
        return {
            id: response.data.id,
            title: response.data.Nom,
        }
    }

    /**
     * return product
     * { id, title, description, category }
     */
    async get_product(id){
        const response = await axios.get(this.__product_url(id), {...this.authenticated_header()})
        return {
            id: response.data.id,
            title: response.data.Nom,
            description: response.data.Description,
            category: {
                id: response.data.categorie.id,
                title: response.data.categorie.Nom,
                category_id: response.data.categorie.categorie
            }
        }
    }

    /**
     * insert a new brand
     * form {id, name, description, categoryId, brandId, images, newImage, files, newFiles}
     */
     async insert_product(form) {
        try {
            
            
            let toUploadFiles = (form.files || []).filter(file => file instanceof File)
            let uploadedFiles = (form.files || []).filter(file => !!file.id)

            if (toUploadFiles) {
                let uploadFiles = await Promise.all(toUploadFiles.map(file => this.upload(file)))
                let uploadedFilesIds = uploadFiles.map(response => ({id: response.data[0].id}))
                form.files = uploadedFiles.concat(uploadedFilesIds)
            }

            let toUploadImages = (form.images || []).filter(file => file instanceof File)
            let uploadedImages = (form.images || []).filter(file => !!file.id)

            if (toUploadFiles) {
                let uploadImages = await Promise.all(toUploadImages.map(file => this.upload(file)))
                let uploadedImagesIds = uploadImages.map(response => ({id: response.data[0].id}))
                form.images = uploadedImages.concat(uploadedImagesIds)
            }

            if (form.id) {
                await axios.put(this.__product_url(form.id), {
                    Nom: form.name,
                    Description: form.description,
                    categorie: form.categoryId,
                    marque: form.brandId,
                    FicheTechnique: (form.files || []).map(({id}) => id),
                    Image: (form.images || []).map(({id}) => id),
                    actif: true
                }, {...this.authenticated_header()})
            } else {
                await axios.post(this.__product_base_url, {
                    Nom: form.name,
                    Description: form.description,
                    categorie: form.categoryId,
                    marque: form.brandId,
                    FicheTechnique: (form.files || []).map(({id}) => id),
                    Image: (form.images || []).map(({id}) => id),
                    actif: true
                }, {...this.authenticated_header()})
            }
        } catch (error) {
            throw error
        } 
    }

    /**
     * toggle product 
     * make product actif or inatif
     */
    async toggle_product(id, value) {
        return axios.put(this.__product_url(id), {
            actif: value
        }, {...this.authenticated_header()})
    }

    /**
     * return product[]
     * { id, title, description, category, marque }
     */
     async get_products_by_category_id(id){
        const response = await axios.get(this.__product_base_url+`?categorie=${id}`, {...this.authenticated_header()})
        let products = response.data.map( product => ({
            id: product.id,
            name: product.Nom,
            description: product.Description,
            categoryId : product.categorie && product.categorie.id,
            categoryName: product.categorie && product.categorie.Nom,
            brandId: product.marque && product.marque.id,
            brandName: product.marque && product.marque.Nom,
            actif: product.actif,
            files: product.FicheTechnique.map(fiche => ({
                id: fiche.id,
                name: fiche.name,
                url: fiche.url
            })),
            images: product.Image.map(image => ({
                id: image.id,
                name: image.name,
                url: image.url
            }))
        }))
        products.sort((a, b) => a.marque && b.marque && a.marque.id - b.marque.id)
        return products
    }

    /**
     * 
     * @param {Array[Category]} list
     * 
     * Map to a list of nested categories
     *  
     */
    categorie_list_to_tree(list){

        const roots = list.filter(({categorie}) => categorie === null)
        const leafs = list.filter(({categorie}) => categorie != null)

        const tree = roots.reduce((acc, category) => ([
            ...acc, {id: category.id, name: category.Nom, submenus: []}
        ]), [])

        return leafs.reduce((acc, category) => {
            let parent = acc.filter(({id}) => id === category.categorie.id)[0]
            parent.submenus = [...parent.submenus, {id: category.id, name: category.Nom, parentName: parent.name}]
            return acc
        }, tree)
    }

    async am_i_logged() {
        if (getCookie('jwt') === false) return false
        try {
            await this.get_categories()
        } catch (error) {
            return false
        }
        return true
    }

    async login(form) {
        try {
            const { data } = await axios.post(this.__auth_url, {
                identifier: form.login, // 'login@sbp-concept.re',
                password: form.password // '1234Abcd'
            }, {
                headers: {'Access-Control-Allow-Origin': '*'}
            });
            setCookie('jwt', data.jwt, 1)
        } catch (error) {
            throw error
        }
    }

    jwt_authorization(){
        let jwt = getCookie('jwt')
        return {
            Authorization: `Bearer ${jwt}`
        }
    }

    authenticated_header(){
        let jwt = getCookie('jwt')
        return {
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        }
    }
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
        }
    }
    return false;
}

export default CMS