import React, {useState} from 'react'
import {TextInput, HTMLInputChangeStateCallback} from "../elements/Input"
import {LoadingButton} from "../elements/Button"
import {LoginForm} from "../types"

import CMS from "../cms"
const cms = new CMS()

const LoginPage: React.FunctionComponent<{onLogin: () => void}> = ({onLogin}) => {

    let [error, setError] = useState<boolean>(false)

    let [form, setForm] = useState<LoginForm>({login: "", password: ""})
    const updateFormKey = HTMLInputChangeStateCallback(setForm, () => form)

    let login = async () => {
        try {
            await cms.login(form)
            setError(false)
            onLogin()
        } catch (error) {
            setError(true)
        }
    }

    return (
        <div className="bg-gray-800 h-screen top-0 pt-20">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col border mx-auto w-80">
            <TextInput label={'Email'} value={form.login} onChange={updateFormKey('login')}/>
            <TextInput label={'Mot de passe'} value={form.password} onChange={updateFormKey('password')} type={'password'}/>
            { error && <div className="text-red-500">
                Une erreur est survenue
            </div>}
            <div className="flex items-center">
                <LoadingButton title={'Se connecter'} onClick={(login)}/>
            </div>
        </div>
        </div>
    )
}

export default LoginPage