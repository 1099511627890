import React, {useEffect, useState} from 'react'
import Dashboard from "./component/Dashboard"
import LoginPage from "./component/LoginPage"
import CMS from './cms'

import './App.css';

const cms = new CMS()

function App() {

  let [logged, setLogged] = useState('idle')

  useEffect(() => {
    cms.am_i_logged().then((value) => setLogged(value))
  })

  return (
    (logged === 'idle') ? (<div> </div>) : (logged ? <Dashboard /> : <LoginPage onLogin={() => setLogged(true)}/>)
  )
}

export default App;
