import React, {useState, useEffect} from "react"
import {TextInput, TextareaInput, SelectInput, MultipleFileInput, MultipleImageInput, options, HTMLInputChangeStateCallback} from "../elements/Input"
import {ModalForm} from "../elements/modalForm"
import {Product, ProductForm, Brand, Category, file} from "../types"
import CMS from '../cms'

const cms = new CMS()


const Form: React.FunctionComponent<{
    form: ProductForm,
    onCancel: () => void,
    onSave: (form: ProductForm) => Promise<void>
}> = ({form, onSave, onCancel}) => {

    let [updatedForm, updateForm] = useState<ProductForm>(form)
    const updateFormKey = HTMLInputChangeStateCallback(updateForm, () => updatedForm)

    let [categories, setCategories] = useState<options[] | null>(null)
    let [brands, setBrands] = useState<options[] | null>(null)

    let retrieveCategories = async () => {
        let categories = categoriesToFlattenOptions(await cms.get_categories())
        updateForm({...updatedForm, categoryId: categories[0].value})
        setCategories(categories)
    }
    let retrieveBrands = async () => {
        let brands = (await cms.get_brands()).map((e: Brand) => ({value: e.id, text: e.name}))
        updateForm({...updatedForm, brandId: brands[0].value})
        setBrands(brands)
    }

    useEffect(() => {
        if (!categories) retrieveCategories()
        if (!brands) retrieveBrands()
    })  

    let onChangeFiles = (files: (file | File)[]) => updateForm({
        ...updatedForm,
        files: files
    })

    let onChangeImages = (files: (file | File)[]) => updateForm({
        ...updatedForm,
        images: files
    })

    return (
        <ModalForm label={'Nouveau Product'} onCancel={onCancel} onSave={() => onSave(updatedForm)}>

          <TextInput
            label={'Nom'}
            onChange={updateFormKey('name')}
            value={updatedForm.name}
          />

          <SelectInput
            label={'Catégorie'}
            onChange={updateFormKey('categoryId', parseInt)} 
            value={updatedForm.categoryId}
            options={(categories || [])}
          />

          <SelectInput
            label={'Marque'} 
            onChange={updateFormKey('brandId', parseInt)}
            value={updatedForm.brandId || ''} 
            options={(brands || [])}
          />

          <MultipleFileInput 
            label={'Fichiers Techniques'}
            value={updatedForm.files}
            onChange={onChangeFiles}
          />

          <MultipleImageInput
            label={'Image'}
            value={updatedForm.images}
            onChange={onChangeImages}
          />

          <TextareaInput
            label={'Description'}
            onChange={updateFormKey('description')}
            value={updatedForm.description}
           />
          
        </ModalForm>
    )


}

const Page: React.FunctionComponent<{category: Category}> = ({category}) => {

    const [products, setProducts] = useState<Product[] | undefined>(undefined)
    const [form, setForm] = useState<ProductForm | undefined>(undefined)

    const retrieveProducts = () => cms.get_products_by_category_id(category.id).then(products => setProducts(products.filter((p: Product) => p.actif === true)))

    useEffect(() => {
        cms.get_products_by_category_id(category.id).then(products => setProducts(products.filter((p: Product) => p.actif === true)))
    }, [category])

    let newProduct = () => {
        setForm({
            id: null,
            name:'',
            description: '',
            categoryId: category.id,
            brandId: null,
            images: [],
            files: [],
        })
    }

    let saveProduct = async (form: ProductForm) => {
        try {
            await cms.insert_product(form)
            setForm(undefined)
            retrieveProducts()
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    let updateProduct = (product: Product) => () => {
        setForm({
            id: product.id,
            name: product.name,
            description: product.description,
            categoryId: product.categoryId,
            brandId: product.brandId,
            images : product.images,
            files: product.files,
        })
    }


    let onDeleteProduct = (product: Product) => async () => {
        if (window.confirm('voulez vous vraiment supprimer le produit ?')) {
            await cms.toggle_product(product.id, false)
            retrieveProducts()
        }
    }

    return (
        <>
        <div className="w-full flex flex-col">
            <div className="w-full text-left mt-5 ml-5 text-gray-800">
                {category.parentName} / {category.name}
            </div>
            <div className="w-full text-left mt-5 ml-5 font-bold text-gray-800">
                <button className="bg-blue-500 text-white font-bold p-2 rounded text-sm" onClick={newProduct}> Nouveau Produit </button>
            </div>
            { (products || []).length === 0 && <div className="mt-10 w-full text-center"> Il n'y a pas de produits </div> }
            { (products || []).length !== 0 && 
            <div className="mt-10">
                <div className="bg-white pb-4 px-4 rounded-md w-full">
                <div className="overflow-x-auto">
                    <table className="table-auto border-collapse w-full">
                        <thead>
                        <tr className="rounded-lg text-sm font-medium text-gray-700 text-left">
                            <th className="px-4 py-2 bg-gray-200 w-10">#</th>
                            <th className="px-4 py-2 bg-gray-200">Marque</th>
                            <th className="px-4 py-2 bg-gray-200">Nom</th>
                            <th className="px-4 py-2 bg-gray-200">Documents</th>
                            <th className="px-4 py-2 bg-gray-200"></th>
                        </tr>
                        </thead>
                        <tbody className="text-sm font-normal text-gray-700">
                        {(products || []).map(product => (
                            <tr key={product.id} className="hover:bg-gray-100 border-b border-gray-200 py-10">
                            <td className="px-4 py-4 text-left">{product.id}</td>
                            <td className="px-4 py-4 text-left">{product.brandName}</td>
                            <td className="px-4 py-4 text-left">{product.name}</td>
                            <td className="px-4 py-4 text-left">
                                {product.files.map(file => (
                                    <a href={file.url} key={file.id} target={"_blank"} rel="noreferrer" className="cursor-pointer text-blue-500 block" download> {file.name} </a>
                                ))}
                            </td>
                            <td className="px-4 py-4 text-left"> 
                                <button className="bg-blue-500 text-white p-1 rounded text-xs mr-2" onClick={updateProduct(product)}> modifier </button>
                                <button className="bg-red-500 text-white p-1 rounded text-xs" onClick={onDeleteProduct(product)}> supprimer </button> 
                            </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                </div>
            </div> }
        </div>
        { form && <Form form={form} onCancel={()=>setForm(undefined)} onSave={saveProduct}/>}
        </>
    )
}

const categoriesToFlattenOptions = (categories: Category[]) => categories.reduce((options: options[], category: Category) => ([
    ...options,
    ...(category.submenus || []).map(menu => ({value: menu.id, text: `${category.name} / ${menu.name}`}))
]), [])


export default Page