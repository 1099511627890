import React, {useEffect, useState} from 'react'
import BrandPage from "./BrandPage"
import ProductPage from "./ProductPage"
import {Category} from "../types"


import CMS from '../cms'
const cms = new CMS()

const Dashboard: React.FunctionComponent = () => {

    const [menuBrandSelected, setMenuBrand] = useState(false)
    const [categories, setCategories] = useState<Category[] | null>(null)
    const [rootCategory, setRootCategory] = useState<Category | null>(null)
    const [category, setCategory] = useState<Category | null>(null)
  
    useEffect(() => {
      if (!categories) cms.get_categories().then((categories) => {
        setCategories(categories)
        setRootCategory(categories[0])
        setCategory(categories[0].submenus[0])
      })
    })
  
    let isRootCategory = (aCategory: Category) => (rootCategory && aCategory && aCategory.id === rootCategory.id)
    let isSelected = (aCategory: Category) => (category && aCategory && aCategory.id === category.id)
  
    let selectRoot = (category: Category) => {
      setRootCategory(category)
      if (category.submenus) setCategory(category.submenus[0])
      setMenuBrand(false)
    }
  
    let selectCategory = (aCategory: any) => {
      setCategory(aCategory)
      setMenuBrand(false)
    }
  
    let selectMenuBrand = () => {
      setMenuBrand(true)
      setRootCategory(null)
      setCategory(null)
    }
  
    return (
      
      <div className="App flex mx-auto">
        <div className="w-50">
          <div className="py-5 px-2 w-56 self-center bg-blue-700 font-bold text-white"> SBP concept </div>
          <div className="w-50 text-left bg-gray-800 h-screen pt-5 pl-2">
            <div className="text-gray-500 text-xl pl-2 pb-2"> Catégories </div>
              {(categories || []).map(category => (
                <div  key={category.id} className={(isRootCategory(category) ? 'border-blue-500 text-blue-500' : 'border-gray-800' )+" pl-5 text-gray-300 py-2 text-md hover:bg-gray-900 cursor-pointer border-l-4  hover:border-blue-500"}> 
                  <div onClick={() => selectRoot(category)}> {category.name} </div>
                  { isRootCategory(category) && (
                    (category.submenus || []).map(menu => <div key={menu.id} className={(isSelected(menu) ? 'text-blue-500' : 'text-white' ) + " text-md pl-3 font-normal hover:text-blue-500"} onClick={() => selectCategory(menu)}> {menu.name} </div>)
                  )}
                </div>
              ))}
            <div className="text-gray-500 text-xl pl-3 pb-2 mt-4"> Marques </div>
            <div onClick={selectMenuBrand} className={(menuBrandSelected ? 'text-blue-500' : 'text-white' ) + " text-md pl-7 font-normal hover:text-blue-500"}> Liste des marques </div>
          </div>
        </div>
  
  
      { menuBrandSelected && <BrandPage /> }
      { category && <ProductPage category={category} /> }
  
      </div>
    );
}

export default Dashboard