import React, {useState, useEffect} from "react"
import {ModalForm} from "../elements/modalForm"
import {TextInput, ImageInput, HTMLInputChangeStateCallback} from "../elements/Input"
import CMS from '../cms'
import {Brand, BrandForm} from '../types'

const cms = new CMS()


const Form: React.FunctionComponent<{form: BrandForm, onCancel: () => void, onSave: (form: BrandForm) => Promise<void>}> = ({form, onCancel, onSave}) => {

  let [updatedForm, updateForm] = useState<BrandForm>(form)
  const updateFormKey = HTMLInputChangeStateCallback(updateForm, () => updatedForm)
  
  let onLogoChange = (file: File) => updateForm({...updatedForm, logo: file})

  return (
    <ModalForm label={'Nouvelle Marque'} onCancel={onCancel} onSave={() => onSave(updatedForm)}>
          <TextInput
            label={'Nom'}
            onChange={updateFormKey('name')}
            value={updatedForm.name}
          />
          <ImageInput 
            label={'Logo'}
            value={updatedForm.logo}
            onChange={onLogoChange}
          />
    </ModalForm>
  )
}

const Page: React.FunctionComponent = () => {

    const [brands, setBrands] = useState<Brand[] | undefined>(undefined)
    const [form, setForm] = useState<BrandForm | undefined>(undefined)
    
    useEffect(() => {
        if (!brands) cms.get_brands().then(brands => setBrands(brands))
    })

    let newBrand = () => {
        setForm({
            id: null,
            name: "",
            logo: null,
        })
    }

    let editBrand = (brand: Brand) => () => {
      setForm({
        id: brand.id,
        name: brand.name,
        logo: brand.logo
      })
    }

    let deleteBrand = (id: number | null) => async () => {
      if (!id) return
      if (window.confirm('voulez-vous vraiment supprimer la marque')) {
        await cms.delete_brand(id)
        cms.get_brands().then(brands => setBrands(brands))
      }
    }

    let saveBrand = async (brand: BrandForm) => {
      await cms.insert_brand(brand)
      cms.get_brands().then(brands => setBrands(brands))
      setForm(undefined)
    }

    return (
        <>
          <div className="w-full flex flex-col">
          <div className="w-full text-left mt-5 ml-5 font-bold text-gray-800">
            Liste des marques
          </div>
          <div className="w-full text-left mt-5 ml-5 font-bold text-gray-800">
            <button className="bg-blue-500 text-white font-bold p-2 rounded text-sm" onClick={newBrand}> Nouvelle Marque </button>
          </div>
          <div className="bg-white pb-4 px-4 rounded-md w-full">
              <div className="overflow-x-auto mt-6">
                <table className="table-auto border-collapse w-full">
                  <thead>
                    <tr className="rounded-lg text-sm font-medium text-gray-700 text-left">
                      <th className="px-4 py-2 bg-gray-200 w-10">#</th>
                      <th className="px-4 py-2 bg-gray-200">Nom</th>
                      <th className="px-4 py-2 bg-gray-200">Logo</th>
                      <th className="px-4 py-2 bg-gray-200"></th>
                    </tr>
                  </thead>
                  <tbody className="text-sm font-normal text-gray-700">
                    {(brands || []).map(brand => (
                      <tr key={brand.id} className="hover:bg-gray-100 border-b border-gray-200 py-10">
                        <td className="px-4 py-4 text-left">{brand.id}</td>
                        <td className="px-4 py-4 text-left">{brand.name}</td>
                        <td> { brand.logo && <img alt="logo" className="w-20 h-20" src={brand.logo.url} /> }</td>
                        <td> 
                          <button className="bg-blue-500 text-white p-2 rounded text-xs mr-2" onClick={editBrand(brand)}> modifier </button> 
                          <button className="bg-red-500 text-white p-2 rounded text-xs" onClick={deleteBrand(brand.id)}> supprimer </button> 
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          { form && <Form form={form} onCancel={()=>setForm(undefined)} onSave={saveBrand}/>}
        </>
    )
}

export default Page