import React from "react"
import {SvgTrash} from "./Svg"

export interface options {value: number, text: string}
export interface file {id: number, name: string, url: string}

export const TextInput: React.FunctionComponent<{
    label: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    value: string | ReadonlyArray<string> | number;
    required?: boolean | undefined
    type?: string
}> = ({label, onChange, value, required, type}) => {
    return (
        <div className="mb-6 text-left">
                <label className="block mb-2 text-md font-bold text-gray-600 dark:text-gray-400">{label}</label>
                <input 
                    required={required}
                    onChange={onChange}
                    value={value} 
                    type={type}
                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                />
        </div>
    )
}

export const TextareaInput: React.FunctionComponent<{
    label: string,
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
    value: string | ReadonlyArray<string> | number;
    required?: boolean | undefined
}> = ({label, onChange, value, required}) => {
    return (
        <div className="mb-6 text-left">
                <label className="block mb-2 text-md font-bold text-gray-600 dark:text-gray-400">{label}</label>
                <textarea 
                    required={required}
                    onChange={onChange}
                    value={value} 
                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" 
                />
        </div>
    )
}


export const SelectInput: React.FunctionComponent<{
    label: string,
    options: options[],
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    value: string | ReadonlyArray<string> | number;
    required?: boolean | undefined
}> = ({label, options, onChange, value, required}) => {
    return (
        <div className="mb-6 text-left">
        <label className="block mb-2 text-md font-bold text-gray-600 dark:text-gray-400">{label}</label>
            <select required onChange={onChange} value={value} className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500">
                {options.map(o => <option value={o.value}> {o.text} </option>)}
            </select>
        </div>
    )
}


export const MultipleFileInput: React.FunctionComponent<{
    label: string,
    value: (file|File)[],
    onChange: (files: (file|File)[]) => void
}> = ({label, value, onChange}) => {

    let removeFile = (file: File | file) => () => onChange(value.filter((f: any) => {
        let afile: any = file

        // case an existing file
        if (!!f.id) return f.id !== afile.id 
        // case a file to upload
        if (!f.id) return f.name !== afile.name

        return true
    }))

    let addFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            onChange([
                ...value,
                event.target.files[0]
            ])
        }
    }

    return (<div className="mb-6 text-left">
        <label className="block mb-2 text-md font-bold text-gray-600 dark:text-gray-400">{label}</label>
            {value.map((file) => (
                <div key={file.name} className="text-md text-gray-900 font-bold"> 
                {file.name} <button className="bg-gray-400 text-white font-bold p-1 rounded text-xs ml-2" onClick={removeFile(file)}> <div className="w-4 h-4"> <SvgTrash /> </div> </button>
                </div>
            ))}
        <input type="file" onChange={addFile} className="w-full mt-2 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
    </div>)
}


export const MultipleImageInput: React.FunctionComponent<{
    label: string,
    value: (file|File)[],
    onChange: (files: (file|File)[]) => void
}> = ({label, value, onChange}) => {

    let removeFile = (file: File | file) => () => onChange(value.filter((f: any) => {
        let afile: any = file

        // case an existing file
        if (!!f.id) return f.id !== afile.id 
        // case a file to upload
        if (!f.id) return f.name !== afile.name

        return true
    }))

    let addFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            onChange([
                ...value,
                event.target.files[0]
            ])
        }
    }

    return (<div className="mb-6 text-left">
        <label className="block mb-2 text-md font-bold text-gray-600 dark:text-gray-400">{label}</label>
            <div className="flex">
            {value.filter((file: any) => !!file.url).map((file: any) => (
                <div key={file.name} className="text-md text-gray-900 font-bold text-center mx-2"> 
                    <img alt="anImage" className="w-36 mb-1" src={file.url} />
                    <button className="bg-gray-400 text-white font-bold p-1 rounded text-xs ml-2" onClick={removeFile(file)}> <div className="w-4 h-4"> <SvgTrash /> </div> </button>
                </div>
            ))}
            </div>
            {value.filter((file: any) => !file.url).map((file: any) => (
                <div key={file.name} className="text-md text-gray-900 font-bold"> 
                    {file.name}
                    <button className="bg-gray-400 text-white font-bold p-1 rounded text-xs ml-2" onClick={removeFile(file)}> <div className="w-4 h-4"> <SvgTrash /> </div> </button>
                </div>
            ))}
        <input type="file" onChange={addFile} className="w-full mt-2 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
    </div>)
}

export const ImageInput: React.FunctionComponent<{
    label: string,
    value: (file|File|null),
    onChange: (file: File) => void
}> = ({label, value, onChange}) => {

    let _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            onChange(event.target.files[0])
        }
    }

    return (
        <div className="mb-6 text-left">
                <label className="block mb-2 text-md font-bold text-gray-600 dark:text-gray-400"> { label } </label>
                { value && (value as any).url && <img className="w-20 h-20" alt="xxx" src={(value as any).url}/> }
                <input type="file" onChange={_onChange}  className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
        </div>
    )
}

// curryfication : build a onChange callback to update state by key from an input event
export const HTMLInputChangeStateCallback = (updateState: (state: any) => void, state: () => any) => (field: string, map: (x:any) => any = x => x) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => updateState({
    ...state(),
    [field]: map(event.target.value)
})